import React from 'react'
import { contact, section5Title, otherLinks, section7Title, publications, social } from '../../profile'

const Contact = () => {

    return (
        <div className="parallax">
            <div data-aos="zoom-in-up" data-aos-once="true" className="git-form">
                <>
                    <div className=" text-center mx-auto">
                        <h1 id="Contact" className="git-head">{section5Title}</h1>
                    </div>
                </>
                <div className="container">
                    <div className="git-cont row">
                        <div className="col-12 col-sm-12">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <div className="inline-block">
                                    <ul>
                                    {publications.map((x) => {
                                       return (<li key={Math.random()}><a title={x.title} href={x.link} target="_blank">{x.title}</a></li>)
                                       }
                                    )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <div className=" text-center mx-auto">
                        <h1 id="Contact" className="git-head">{section7Title}</h1>
                    </div>
                </>
                <div className="container">
                    <div className="git-cont row">
                        <div className="col-12 col-sm-12">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <div className="inline-block">
                                    <ul>
                                    {otherLinks.map((x) => {
                                       return (<li key={Math.random()}><a title={x.title} href={x.link} target="_blank">{x.title}</a></li>)
                                       }
                                    )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <div className=" text-center mx-auto">
                        <h1 id="Contact" className="git-head">Others</h1>
                    </div>
                </>
                <div className="container">
                    <div className="git-cont row">
                        <div className="col-12 col-sm-12">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <div className="inline-block">
                                    <ul>
                                    <li key={Math.random()}><a title="LinkedIn" href={social.linkedin} target="_blank">LinkedIn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p id="not-dark" className="Copy">2024 © Copyright <strong>{contact.copyright}</strong>. All Rights Reserved</p>
        </div>
    )

}

export default Contact
