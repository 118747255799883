// Change website animations
const animation = {
  // make it false to switch off fade-up animation
  animate: true,
  // animation playing duration
  duration: 750,
  // if true, animation plays only once when element comes on screen
  once: false,
};
// Change your display name on tha landing display
const header = {
  name: "Garv Sachdev",
};
const background = {
  // Options: Snow or Particle
  type: "Particle",
};
// Write a para about yourself here
// To update your image, go to './styles/images.css'
const section2title = "Big Journey begins with small steps";
const about = {
  paragraph:
    "I am a dreamer, a budding technologist...ready to spread my wings and explore the world.",
};
// Edit your skill and the percentage you know about it
// To Add a skill, copy any one below and paste it after the last comma
const skillsBar = [
  {
    name: "HTML5",
    // To add a custom svg instead of font-awesome icons, add svg path below otherwise just comment it out
    svg: "M0 32l34.9 395.8L191.5 480l157.6-52.2L384 32H0zm308.2 127.9H124.4l4.1 49.4h175.6l-13.6 148.4-97.9 27v.3h-1.1l-98.7-27.3-6-75.8h47.7L138 320l53.5 14.5 53.7-14.5 6-62.2H84.3L71.5 112.2h241.1l-4.4 47.7z",
    faClass: "fab fa-html5",
  },
  {
    name: "CSS3",
    // svg: '',
    faClass: "fab fa-css3",
  },
  {
    name: "Javascript",
    // svg: '',
    faClass: "fab fa-js",
  },
  {
    name: "SASS",
    // svg: '',
    faClass: "fab fa-sass",
  },
  {
    name: "Node",
    // svg: '',
    faClass: "fab fa-node",
  },
  {
    name: "Python",
    // svg: '',
    faClass: "fab fa-python",
  },
  {
    name: "Java",
    // svg: '',
    faClass: "fab fa-java",
  },
  {
    name: "PHP",
    // svg: '',
    faClass: "fab fa-php",
  },
  {
    name: "Database",
    // svg: '',
    faClass: "fas fa-database",
  },
  {
    name: "AWS",
    // svg: '',
    faClass: "fab fa-aws",
  },
];
// Edit your projects, its name, your skills used to make it, and the url.
// You can omit freely anything if you dont have it
// To Add a Project, copy any one below and paste it after the last comma and increment the id's project number
const section3Title = "My Projects";
const projects = [
  {
    // Add image in './styles/images.css' in #project1
    id: "project1",
    name: "Healert",
    skills: ["Python, Tkinter"],
    url: "https://github.com/gavkujo/Healert",
  },
  {
    // Add image in './styles/images.css' in #project2
    id: "project2",
    name: "GavBot",
    skills: ["Python, DiscordAPI"],
    url: "https://github.com/gavkujo/gavbot",
  },
  {
    // Add image in './styles/images.css' in #project3
    id: "project3",
    name: "SusBot",
    skills: ["Python, DiscordAPI"],
    url: "https://github.com/gavkujo/susbot",
  },
  {
    // Add image in './styles/images.css' in #project3
    id: "project4",
    name: "Simulation of Particles",
    skills: ["Python"],
    url: "https://garvsachdev.com/documents/Garv_Research_Project.pdf",
  },
  {
    // Add image in './styles/images.css' in #project4
    id: "project5",
    name: "MusicBot",
    skills: ["Python"],
    url: "https://github.com/gavkujo/MusicBot",
  }
  // {
  //   // Add image in './styles/images.css' in #project5
  //   id: "project5",
  //   name: "Project 5",
  //   skills: ["HTML, CSS, JS"],
  //   url: "https://github.com/kaustubhai",
  // },
  // {
  //   // Add image in './styles/images.css' in #project6
  //   id: "project6",
  //   name: "Project 6",
  //   skills: ["HTML, CSS, JS"],
  //   url: "https://github.com/kaustubhai",
  // },
];
// Edit your Miscellaneous Activities, its name and the url.
// You can omit freely anything if you dont have it
// To Add a Activity, copy any one below and paste it after the last comma and increment the id's Miscellaneous number
const section4Title = "My Art";
const miscellaneous = [
  {
    // Add image in './styles/images.css' in #misc1
    id: "misc1",
    name: "Fort of Mystery",
    url: "https://garvsachdev.com/documents/Fine-Art-Painting4.jpeg",
  },
  {
    // Add image in './styles/images.css' in #misc2
    id: "misc2",
    name: "All Alone",
    url: "https://garvsachdev.com/documents/Fine-Art-Painting3.jpeg",
  },
  {
    // Add image in './styles/images.css' in #misc3
    id: "misc3",
    name: "Bulls Eye",
    url: "https://garvsachdev.com/documents/Fine-Art-Painting2.jpeg",
  },
  {
    // Add image in './styles/images.css' in #misc1
    id: "misc4",
    name: "Pot",
    url: "https://garvsachdev.com/documents/Fine-Art-Painting1.jpeg",
  },
  {
    // Add image in './styles/images.css' in #misc2
    id: "misc5",
    name: "Telephone",
    url: "https://garvsachdev.com/documents/Telephone-Lino-Print.jpeg",
  },
  {
    // Add image in './styles/images.css' in #misc2
    id: "misc6",
    name: "Cartoon Sketch",
    url: "https://garvsachdev.com/documents/ComicStrip-Competition-Submission.pdf",
  },
];

// Edit your Music Activities, its name and the url.
// You can omit freely anything if you dont have it
// To Add a Activity, copy any one below and paste it after the last comma and increment the id's Miscellaneous number
const section6Title = "My Music";
const music = [
  {
    // Add image in './styles/images.css' in #music1
    id: "music1",
    name: "Apple Sake",
    url: "https://open.spotify.com/album/4Xn05p3yMb8Asxl7547qFU?si=NTyIObwST+9GD941RHwihRA&nd=1",
  },
  {
    // Add image in './styles/images.css' in #music2
    id: "music2",
    name: "Stuff I made with Garage Band",
    url: "https://open.spotify.com/album/3xBCpadYYQDPzqjW1A5wQW?si=InCbIDP+zT4iBkpYe74Hdqw&nd=1",
  },
  // {
  //   // Add image in './styles/images.css' in #music3
  //   id: "music3",
  //   name: "Music 3",
  //   url: "https://github.com/kaustubhai",
  // },
];

// Edit your Soial Work Activities, its name and the url.
// You can omit freely anything if you dont have it
// To Add a Activity, copy any one below and paste it after the last comma and increment the id's Miscellaneous number
// const section7Title = "My Conscience";
// const conscience = [
//   {
//     // Add image in './styles/images.css' in #music1
//     id: "conscience1",
//     name: "Music 1",
//     url: "https://github.com/kaustubhai",
//   },
//   {
//     // Add image in './styles/images.css' in #music2
//     id: "conscience2",
//     name: "Music 2",
//     url: "https://github.com/kaustubhai",
//   },
//   {
//     // Add image in './styles/images.css' in #music3
//     id: "conscience3",
//     name: "Music 3",
//     url: "https://github.com/kaustubhai",
//   },
// ];


// Contact form text, and Formspree link(to send a submit contact through their API as in contact.js)
// To get your own jotform link, go to https://formspree.io/
// If you hacve the link already, paste it in the contactUrl below
const section5Title = "Publications";
const contact = {
  pitch:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris nunc congue nisi vitae.",
  copyright: "Garv Sachdev",
  contactUrl: "",
};
const publications = [{
  title: "Wonders of Artificial Intelligence - Free Press Journal",
  link: "https://www.freepressjournal.in/education/what-are-the-wonders-of-artificial-intelligence-ai"
},
{
  title: "Impact of Robotics and Automation on Employment - Free Press Journal ",
  link: "https://www.freepressjournal.in/education/impact-of-robotics-and-automation-on-employment"
},
{
  title: "Sine Quo Non of AI - The Hindustan Times",
  link: "https://garvsachdev.com/documents/Sine-Quo-Non-of-AI.jpeg"
}]
const section7Title = "Social Work";
const otherLinks = [
{
  title: "Community Service at Girija Welfare Association Shelters for Orphans and Elderly",
  link: "https://garvsachdev.com/documents/Social-Work-Girija-Welfare-Association.pdf"
},
{
  title: "STEM Reference Book1 for Primary Children",
  link: "https://garvsachdev.com/documents/Reference-Book-For-Local-Municipal-School.pdf"
},
{
  title: "STEM Reference Book2 for Primary Children",
  link: "https://garvsachdev.com/documents/STEM-Force-and-Motion.pdf"
}
]

// Paste your respective social media links. You can omit any if you dont have it
// Upload your resume in your drive, get the shaareable link and paste it in the resume section
const social = {
  github: "https://github.com/gavkujo",
  // facebook: "https://facebook.com",
  // twitter: "https://twitter.com",
  // instagram: "https://instagram.com",
  linkedin: "https://www.linkedin.com/in/garv-sachdev-838287227/",
  // resume: "https://novoresume.com/",
};
// Dont change anything here
export {
  animation,
  header,
  background,
  about,
  skillsBar,
  projects,
  miscellaneous,
  music,
  // conscience,
  contact,
  social,
  section2title,
  section3Title,
  section4Title,
  section5Title,
  section6Title,
  section7Title,
  otherLinks,
  publications
  // section7Title
};
